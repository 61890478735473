import { useCallback } from 'react';

import { ApplicationPage } from 'app/types';
import PasswordRecovery from 'common/containers/password-recovery';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

const TITLE = 'Password Recovery';

const RecoveryPasswordPage: ApplicationPage = () => {
  const navigate = useRouterNavigate();

  const handleSuccess = useCallback(() => {
    return navigate(Routes.Home);
  }, [navigate]);
  const handleCancel = useCallback(() => {
    return navigate(-1);
  }, [navigate]);

  return (
    <PasswordRecovery
      onSuccess={handleSuccess}
      onCancel={handleCancel}
    />
  );
};

RecoveryPasswordPage.getMeta = () => ({ title: TITLE });

RecoveryPasswordPage.getMainLayoutProps = () => ({
  size: 'sm',
  centered: true,
});

export default RecoveryPasswordPage;
